.text-logo {
  font-family: $font-header;
}
.text-sublogo {
  font-family: $font-subheader;
}
.text-logo-bold {
  font-family: $font-header-bold;
  text-shadow: 2px 2px 4px rgba(11,48,77,0.89);
}

nav.navbar {
  .nav-link {
    padding: {
      top: 2.4rem;
      bottom: 2.4rem;
    }
    &:hover, &.active {
      background-color: $primary;
      color: $white;
    }
  }
}

.talks {
  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top:100%;
    left:100px;
    border: {
      style: solid;
      width: 20px 50px 0 50px;
      color: $light transparent transparent transparent;
    }
    filter: drop-shadow(0 .5rem .4rem rgba(0,0,0,.15));
  }
}

.arrowdiv {
  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left:100%;
    top:100px;
    transform: translateY(-50%);
    border: {
      style: solid;
      width: 50px 0 50px 20px;
      color: transparent transparent transparent $light;
    }
  }
}

.z-9999 {
  z-index: 9999;
}

@mixin cursors($name) {
  .cursor-#{$name} {
    cursor: #{$name};
  }
}

@include cursors("pointer");
@include cursors("crosshair");

@for $i from 1 through 10 {
  .rotate-#{$i*10} {
    transform: rotate(#{$i*10}deg);
  }
  .rotate--#{$i*10} {
    transform: rotate(#{$i*-10}deg);
  }
}

@for $i from -1 through 10 {
  .z-#{$i} {
    z-index: #{$i};
  }
}

@include media-breakpoint-down(lg) {
  nav.navbar {
    .nav-link {
      padding: 1rem;
    }
  }

  .arrowdiv {
    &::after {
      left:50%;
      top:100%;
      transform: translateX(-50%);
      border: {
        width: 20px 50px 0 50px;
        color: $light transparent transparent transparent;
      }
    }
    margin-bottom: 2rem;
  }
}

@include media-breakpoint-down(md) {
  @for $i from 1 through 10 {
    .rotate-#{$i*10} {
      transform: rotate(0deg);
    }
    .rotate--#{$i*10} {
      transform: rotate(0deg);
    }
  }
}

@include media-breakpoint-down(sm) {
  nav.navbar {
    .navbar-toggler {
      margin-bottom: 10px;
    }
  }

  .text-logo {
    span.text-danger {
      font-size: 18px !important;
    }
  }
}
