// Body
$white: #ffffff;
$primary: #1b75bb;
$secondary: #90278e;
$warning: #f6921e;
$danger: #ed4f9b;
$success: #b3d13e;
$light: #F8FAFC;

$body-bg: $white;

// custom
$font-header: 'Quicksand', sans-serif;
$font-header-bold: 'Anton', sans-serif;
$font-subheader: 'Mr Dafoe', cursive;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1.2rem;
$line-height-base: 1.6;

// navbar
$navbar-brand-margin-end: 2.2rem;
$navbar-nav-link-padding-x: 1.2rem;
$navbar-padding-y: 0;
